import {
    computePosition, flip, shift, offset, arrow,
} from '@floating-ui/dom';
import { ref } from 'vue';

/*
* Most of the below was taken verbatim from the floating-ui tutorial for tooltips
* which can be found here:
* https://floating-ui.com/docs/tutorial
*/
const ttip_parents = document.querySelectorAll('.tooltip-parent');

function update(tooltipParent) {
    const ttip = tooltipParent.querySelector('.tooltip');
    const arrowElement = ttip.querySelector('.tooltip-arrow');
    computePosition(tooltipParent, ttip, {
        placement: 'bottom',
        middleware: [
            offset(1),
            flip(),
            shift(),
            arrow({ element: arrowElement }),
        ],
    }).then(({
        x, y, placement, middlewareData,
    }) => {
        Object.assign(ttip.style, {
            left: `${x}px`,
            top: `${y}px`,
        });

        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[placement.split('-')[0]];

        Object.assign(arrowElement.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-4px',
        });
    });
}

function showTooltip(event) {
    const tooltip = event.target.querySelector('.tooltip');
    tooltip.style.display = 'block';
    update(event.target);
}

function hideToolTip(event) {
    const tooltip = event.target.querySelector('.tooltip');
    tooltip.style.display = '';
}

for (const ttip_parent of ttip_parents) {
    [
        ['mouseenter', showTooltip],
        ['mouseleave', hideToolTip],
        ['focus', showTooltip],
        ['blur', hideToolTip],
        // ['click', toggleToolTip],
    ].forEach(([event, listener]) => {
        ttip_parent.addEventListener(event, listener);
    });
}

function isTouchDevice() {
    return window.matchMedia('(pointer: coarse)').matches;
}

const author_bio_parents = document.querySelectorAll('.author-link');

function updateAuthorBio(tooltipParent) {
    const author_bio = tooltipParent.querySelector('.author-hover-bio');
    computePosition(tooltipParent, author_bio, {
        placement: 'bottom',
        middleware: [
            offset(1),
            flip(),
            shift({ padding: 50 }),
        ],
    }).then(({
        x, y,
    }) => {
        Object.assign(author_bio.style, {
            left: `${x}px`,
            top: `${y}px`,
        });
    });
}

function showAuthorBioTooltip(event) {
    event.preventDefault();
    const author_link_parent = event.target.parentElement;
    const author_hover_bio = author_link_parent.querySelector('.author-hover-bio');

    author_hover_bio.style.display = 'block';
    author_hover_bio.style.zIndex = '10';
    updateAuthorBio(author_link_parent);
}

function hideAuthorBioToolTip(event) {
    const author_link_parent = event.target.parentElement;
    console.log(event.type)

    if (event.type === 'blur') {
        if (event.relatedTarget) {
            if (event.relatedTarget.nodeName === 'A') {
                // We want to allow links to be clicked
                return;
            }
        }
    }

    const author_hover_bio = author_link_parent.querySelector('.author-hover-bio');
    author_hover_bio.style.display = '';
    author_hover_bio.style.zIndex = '0';
}

function toggleAuthorBioTooltip(event) {
    event.preventDefault();
    const author_link_parent = event.target.parentElement;
    const author_hover_bio = author_link_parent.querySelector('.author-hover-bio');

    if (author_hover_bio.style.display === 'block') {
        hideAuthorBioToolTip(event);
    } else {
        showAuthorBioTooltip(event);
    }
}

for (const auth_bio_parent of author_bio_parents) {
    [
        ['blur', hideAuthorBioToolTip],
        ['click', toggleAuthorBioTooltip],
        ['touched', toggleAuthorBioTooltip],

    ].forEach(([event, listener]) => {
        auth_bio_parent.addEventListener(event, listener);
    });
}
